import React from 'react';
import profile from 'assets/profile.jpg';

import { ReactComponent as LinuxLogo } from 'assets/logos/linux.svg';
import { ReactComponent as ReactLogo } from 'assets/logos/react.svg';
import { ReactComponent as HtmlLogo } from 'assets/logos/html5.svg';
import { ReactComponent as CssLogo } from 'assets/logos/css3.svg';
import { ReactComponent as SassLogo } from 'assets/logos/sass.svg';
import { ReactComponent as JavascriptLogo } from 'assets/logos/javascript.svg';
import { ReactComponent as TypescriptLogo } from 'assets/logos/typescript.svg';
import { ReactComponent as BootstrapLogo } from 'assets/logos/bootstrap.svg';
import { ReactComponent as JiraLogo } from 'assets/logos/jira.svg';
import { ReactComponent as GitLogo } from 'assets/logos/git.svg';
import { ReactComponent as GithubLogo } from 'assets/logos/github.svg';
import { ReactComponent as BitbucketLogo } from 'assets/logos/bitbucket.svg';
import { ReactComponent as GitlabLogo } from 'assets/logos/gitlab.svg';
import { ReactComponent as WordpressLogo } from 'assets/logos/wordpress.svg';
import { ReactComponent as AdobeXdLogo } from 'assets/logos/adobe-xd.svg';
import { ReactComponent as PostgreSqlLogo } from 'assets/logos/postgresql.svg';
import { ReactComponent as GrafanaLogo } from 'assets/logos/grafana.svg';
import { ReactComponent as JestLogo } from 'assets/logos/jest.svg';
import { ReactComponent as CypressLogo } from 'assets/logos/cypress.svg';
import { ReactComponent as JavaLogo } from 'assets/logos/java.svg';
import { ReactComponent as SpringLogo } from 'assets/logos/spring.svg';
import { ReactComponent as MariaDBLogo } from 'assets/logos/mariadb.svg';
import { ReactComponent as NextJSLogo } from 'assets/logos/nextjs.svg';
import { ReactComponent as TailwindCSSLogo } from 'assets/logos/tailwindcss.svg';
import { ReactComponent as BashLogo } from 'assets/logos/bash.svg';
import { ReactComponent as MicronautLogo } from 'assets/logos/micronaut.svg';
import { ReactComponent as MjmlLogo } from 'assets/logos/mjml.svg';
import { ReactComponent as FigmaLogo } from 'assets/logos/figma.svg';
import { ReactComponent as StyledComponentsLogo } from 'assets/logos/styled-component.svg';
import { ReactComponent as AngularJSLogo } from 'assets/logos/angularjs.svg';
import { ReactComponent as CSharpLogo } from 'assets/logos/c-sharp.svg';
import { ReactComponent as CPlusPlusLogo } from 'assets/logos/c++.svg';
import { ReactComponent as PythonLogo } from 'assets/logos/python.svg';
import { ReactComponent as NumpyLogo } from 'assets/logos/numpy.svg';
import { ReactComponent as PandasLogo } from 'assets/logos/pandas.svg';
import { ReactComponent as DockerLogo } from 'assets/logos/docker.svg';
import { ReactComponent as JenkinsLogo } from 'assets/logos/jenkins.svg';
import { ReactComponent as SonarQubeLogo } from 'assets/logos/sonarqube.svg';
import { ReactComponent as ESLintLogo } from 'assets/logos/eslint.svg';
import { ReactComponent as SlackLogo } from 'assets/logos/slack.svg';
import { ReactComponent as MattermostLogo } from 'assets/logos/mattermost.svg';
import { ReactComponent as CloudflareLogo } from 'assets/logos/cloudflare.svg';
import { ReactComponent as HostingerLogo } from 'assets/logos/hostinger.svg';

import './styles.css';
import SideBar from 'components/sideBar';

function App() {
  document.title = 'GregorySpace';
  const bornDate = new Date(2001, 3, 18);

  function calculateYearDifference(bornDate: Date) {
    const today = new Date();

    return Math.floor(Math.ceil(Math.abs(bornDate.getTime() - today.getTime()) / (1000 * 3600 * 24)) / 365.25);
  }

  function calculateDaysLived(bornDate: Date) {
    const today = new Date();
    const timeDifference = today.getTime() - bornDate.getTime();
    const daysLived = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysLived;
  }

  return (
    <div className="HomePage">
      <h2 className='HomePage__title'>Bem vindo ao GregorySpace</h2>
      <div>
        <div className='HomePage__about'>
          <div className='HomePage__aboutMe'>
            <div>
              <img src={profile} alt="profile" className='HomePage__aboutMeImage' />
              <div className='HomePage__aboutMeDescription'>
                <span>Eduardo Borges Araujo</span>
                <span>{bornDate.toLocaleDateString('pt-BR')}</span>
                <span>eduardo.araujonf@gmail.com</span>
              </div>
            </div>
          </div>
          <div>
            <div className='HomePage__aboutWeb'>
              <h3>Sobre o website</h3>
              <div>
                <span>
                  O objetivo deste website é ser um portfólio completo, onde irei compartilhar minhas experiências detalhadas como desenvolvedor de software. Além disso, será um espaço para explorar projetos pessoais, apresentar meus hobbies e interesses e disponibilizar guias de experimentos self-hosted.
                  <br />
                  Aqui, irei mostrar um pouco mais sobre quem sou, reunindo conteúdos que refletem minhas habilidades e meus conhecimentos sobre a técnologia.
                  <br />
                  <br />
                  Para iniciar, meu nome é Eduardo, mais conhecido como Greg. ¯\_(ツ)_/¯
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='HomePage__content'>
          <div className='HomePage__skills'>
            <div className='HomePage__neofetch'>
              <pre style={{
                fontSize: '0.8rem',
              }}>
                {`eduardo@GregorySpace\n-----------------\nUsername: Greg\nDegree: Software Engineer - 2023\nPost-degree: DevOps & ESC - In progress\nAge: ${calculateYearDifference(bornDate)} years / ${calculateDaysLived(bornDate)} days\nNationality: Brazilian\nLanguages: [Portuguese/English/Espanish]\nOS: Arch Linux (btw)\nInstrument: Bass (4 string guitar xD)\nHobbies: [Game/Music/Code/Hardware]\nFavorite Game: Left 4 Dead 2\nFavorite Music: Superstar (G-dragon)\nFavorite Drink: Wine Chopp\nFavorite Code: TypeScript\nCPU: Intel i5-9400f (6)\nGPU: Radeon RX 6750 XT`}
              </pre>
              <div>
                <LinuxLogo />
              </div>
            </div>

            <br />
            <div className='HomePage__divider' />
            <br />

            <h3>Formação</h3>
            <br />
            <div>
              <div className='HomePage__experience'>
                <h4>Graduação Engenharia de Software</h4>
                <h6>Inatel - Santa Rita do Sapucaí, MG</h6>
              </div>
              <h6 className='HomePage__experienceTimeStamp'>
                Jul 2018 - Dez 2023
              </h6>
              <span>
                Ingressei na faculdade através do curso de Engenharia Biomédica, após 2 semestres optei por realizar a transferência de curso para Engenharia de Software.
                <br />
                Durante a graduação, Fui introduzido a algoritmo com a linguagem C++. Posteriormente adquiri conhecimento do paradigma Orientado a Objetos com a linguagem Java.
                <br />
                Realizei projetos com C#, Python, JavaScript e Docker, utilizando frameworks como Angular e React, além de bibliotecas como NumPy e Pandas. Encontrei afinidade com a área de DevOps, que me levou a buscar uma especialização na área.
              </span>
              <div className='HomePage__aboutMeTools'>
                <GitLogo />
                <GithubLogo />
                <JavascriptLogo />
                <HtmlLogo />
                <CssLogo />
                <ReactLogo />
                <AngularJSLogo />
                <CPlusPlusLogo />
                <CSharpLogo />
                <PythonLogo />
                <NumpyLogo />
                <PandasLogo />
                <DockerLogo />
              </div>
            </div>
            <br />
            <div>
              <div className='HomePage__experience'>
                <h4>Especialização DevOps & Engenharia de Software Contínua</h4>
                <h6>PUC Minas - Passos, MG</h6>
              </div>
              <h6 className='HomePage__experienceTimeStamp'>
                Jul 2024 - Atualmente (Jan 2026)
              </h6>
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus aspernatur placeat eum, dignissimos architecto, perferendis sunt esse inventore atque cupiditate praesentium. Enim similique perferendis laborum autem cupiditate saepe distinctio?
              </span>
              <div className='HomePage__aboutMeTools'>
                <DockerLogo />
                <JenkinsLogo />
                <SonarQubeLogo />
                {/* <AnsibleLogo />
                <TerraformLogo /> */}
              </div>
            </div>

            <br />
            <div className='HomePage__divider' />
            <br />

            <h3>Experiências</h3>
            <div style={{
              padding: '1rem 0',
            }}>
              <div>
                <span>
                  Com o intuito de adquirir experiencia completa do processo de desenvolvimento, realizei projetos pessoais, implementando serviços de infra na plataforma Hostinger. Configurei Jenkins e SonarQube por meio do Docker e Cloudflare Tunnel em uma VPS para monitorar a qualidade de código e automatizar o deploy. Também implementei o pipeline de CI/CD no repositório GitHub para deploy na Hostinger, criando jobs de build, teste, deploy (via FTP) e notificação. Utilizei tecnologias como React, TypeScript, SASS, ESLint, Jest e Shellscript.
                </span>
                <br />
                <div className='HomePage__aboutMeTools'>
                  <GithubLogo />
                  <ReactLogo />
                  <TypescriptLogo />
                  <SassLogo />
                  <JestLogo />
                  <BashLogo />
                  <HostingerLogo />
                  <CloudflareLogo />
                  <DockerLogo />
                  <JenkinsLogo />
                  <SonarQubeLogo />
                  <ESLintLogo />
                </div>
              </div>
              <br />

              <span>
                Em resumo de minha experiência profissional, atuei principalmente no desenvolvimento web utilizando tecnologias como React, Next.js, JavaScript, TypeScript, CSS, Styled Components e Tailwind CSS, aplicando o conceito mobile-first e de responsividade. No backend, desenvolvi APIs RESTful com Java, Spring e Micronaut. Utilizei Jira e LeanTime para gestão ágil, aplicando Scrum e Kanban para acompanhar as entregas. Implantei dashboards e relatórios com Grafana e Chart.js, realizando consultas em PostgreSQL e MariaDB. Além disso, desenvolvi testes automatizados com Jest e Cypress, garantindo a qualidade do código, e automatizei processos no Git com Shellscript e Husky.
              </span>
              <br />
              <br />
              <span>À seguir comentarei de forma mais detalhada minhas experiências profissionais.</span>
            </div>

            <h3 className='HomePage__placeWork'>PumpkinTech - Alfenas, MG</h3>
            <div className='HomePage__placeWorkExperiences'>
              <div>
                <div className='HomePage__experience'>
                  <h4>Estágio</h4>
                  <h6 className='HomePage__experienceTimeStamp'>
                    Out 2021 - Abr 2022
                  </h6>
                </div>
                <span>
                  Atuei no acompanhamento da engenharia de requisitos em um projeto em fase inicial, contribuindo para o desenvolvimento de landing pages com React, TypeScript e Bootstrap. Utilizei o Jira para gestão de projetos e apliquei a metodologia Scrum, implementando o design de interfaces com o auxilio da ferramenta Adobe XD, garantindo uma interface intuitiva e responsiva.
                </span>
                <div className='HomePage__aboutMeTools'>
                  <BitbucketLogo />
                  <JiraLogo />
                  <ReactLogo />
                  <TypescriptLogo />
                  <BootstrapLogo />
                  <AdobeXdLogo />
                  <SlackLogo />
                </div>
              </div>
              <br />
              <div>
                <div className='HomePage__experience'>
                  <h4>Desenvolvedor Frontend</h4>
                  <h6 className='HomePage__experienceTimeStamp'>
                    Abr 2022 - Jun 2023
                  </h6>
                </div>
                <span>
                  Contribuí para o desenvolvimento de aplicações de gerenciamento e supervisão com implementação de formulários, relatórios e dashboards. Utilizei tecnologias como React, TypeScript, Chart.Js e React-hook-form. Elaborei views a partir do banco de dados PostgreSQL para a construção de dashboards no Grafana. O projeto foi gerenciado por meio das plataformas <span className='HomePage__highlight'>Jira e LeanTime¹</span>, seguindo a metodologia ágil Scrum. Implementei o design fornecido pela ferramenta Figma e realizei o desenvolvimento de testes unitários, de integração e de snapshot, utilizando Jest e Cypress, garantindo a qualidade e robustez para futuras implementações.
                </span>
                <br />
                <br />
                <span className='HomePage__highlight'>
                  [1] <span className='HomePage__highlightExplained'>
                    Durante este período houve a migração de infra da empresa para softwares OpenSource.
                  </span>
                </span>
                <div className='HomePage__aboutMeTools'>
                  <BitbucketLogo />
                  <GitlabLogo />
                  <JiraLogo />
                  <ReactLogo />
                  <TypescriptLogo />
                  <PostgreSqlLogo />
                  <GrafanaLogo />
                  <JestLogo />
                  <CypressLogo />
                  <FigmaLogo />
                  <SlackLogo />
                  <MattermostLogo />
                </div>
              </div>
              <br />
              <div>
                <div className='HomePage__experience'>
                  <h4>Desenvolvedor Fullstack</h4>
                  <h6 className='HomePage__experienceTimeStamp'>
                    Jun 2023 - Atualmente
                  </h6>
                </div>
                <span>
                  Atuei no desenvolvimento e manutenção de aplicações CRM, utilizando tecnologias como React, JavaScript, Styled Components, Java, Micronaut, MJML e SQL. Realizei refatorações no front-end para melhorar a performance, aplicar boas práticas e otimizar o SEO do website, além de implementar integrações manuais com a plataforma Pixel do Facebook para monitoramento aprimorado do tráfego dos usuários.
                </span>
                <div className='HomePage__aboutMeTools'>
                  <GitlabLogo />
                  <GithubLogo />
                  <ReactLogo />
                  <JavascriptLogo />
                  <StyledComponentsLogo />
                  <JestLogo />
                  <JavaLogo />
                  <MicronautLogo />
                  <MjmlLogo />
                  <PostgreSqlLogo />
                  <FigmaLogo />
                  <MattermostLogo />
                  <CloudflareLogo />
                </div>
                <br />
                <span>
                  Desenvolvi landing pages interativas utilizando React, TypeScript, Next.js e Tailwind CSS, e criei APIs RESTful com Java e Spring para integrar funcionalidades. Automatizei <span className='HomePage__highlight'>processos manuais e recorrentes²</span> e padronizei fluxos de trabalho no Git utilizando Shellscript e Husky. Supervisionei atividades de estagiários, assegurando consistência e eficiência no desenvolvimento.
                </span>
                <br />
                <br />
                <span>
                  Adotei as metodologias ágeis <span className='HomePage__highlight'>Scrum e Kanban³</span> na plataforma LeanTime, equilibrando o desenvolvimento de novas funcionalidades com a correção de bugs.
                </span>
                <br />
                <br />
                <span className='HomePage__highlight'>
                  [2] <span className='HomePage__highlightExplained'>
                    Realizei a padronização de nomeação para branches e mensagem de commit. Além disso desenvolvi scripts para realizar o auto incremento da versão do software de acordo com a nomeação da branch.
                  </span>
                </span>
                <br />
                <span className='HomePage__highlight'>
                  [3] <span className='HomePage__highlightExplained'>
                    Durante este período, devido ao software legado exigindo alta demanda de correções de bugs e pedindo novas features, houve a necessidade de mudança no modelo de entregas. Com isso migramos de entregas por Sprints para o modelo Kanban. Melhorando a visibilidade do fluxo de trabalho, priorização de tarefas e realizando o alinhamento com a expectativa do cliente.
                  </span>
                </span>
                <div className='HomePage__aboutMeTools'>
                  <GitlabLogo />
                  <ReactLogo />
                  <TypescriptLogo />
                  <NextJSLogo />
                  <TailwindCSSLogo />
                  <JestLogo />
                  <CypressLogo />
                  <JavaLogo />
                  <SpringLogo />
                  <MariaDBLogo />
                  <BashLogo />
                  <FigmaLogo />
                  <MattermostLogo />
                </div>
              </div>
            </div>
            <br />
            <div className='HomePage__placeWorkExperiences'>
              <div>
                <div className='HomePage__experience'>
                  <h4>Freelancer</h4>
                  <h6 className='HomePage__experienceTimeStamp'>
                    Out 2024
                  </h6>
                </div>
                <span>
                  Realizei a implementação de novas funcionalidades e manutenção de website WordPress, utilizando HTML e CSS.
                </span>
                <div className='HomePage__aboutMeTools'>
                  <HtmlLogo />
                  <CssLogo />
                  <WordpressLogo />
                </div>
              </div>
            </div>
          </div>

          <SideBar/>
        </div>
      </div>
    </div>
  );
}

export default App;
