import React from 'react';
import packageJson from '../../../package.json';

export default function SideBar() {
    return (
        <div className='HomePage__sideBar' >
            <div className='HomePage__sideBarOptions'>
                <a href="">Diário</a>
                <a href="">Kanban</a>
                <a href="">Cronograma</a>
                <a href="">Fotos</a>
                <a href="">Utils</a>
                <a href="">Hardware</a>
            </div>
            <span className='HomePage__version'>Versão: {packageJson.version}</span>
        </div>
    );
}